import React, { useState } from "react";
import { API_KEY } from "../utils/constants";
import { signUpAPI } from "../api/api";
import OTPInput from "../components/otpInput";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/loader";

function OTPVerify() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user_name, email, phone, password, createdOtp } =
    location.state || {};

  const [otp, setOTP] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    if (otp == createdOtp) {
      setIsLoading(true)
      const formData = new FormData();
      formData.append("__api_key__", API_KEY);
      formData.append("user_name", user_name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("password", password);
      await signUpAPI(formData)
        .then((res) => {
          setIsLoading(false)
          if (res.data.state === "OK") {
            localStorage.setItem(
              "user",
              JSON.stringify(res.data.data.user_entity)
            );
            navigate("/home");
          } else if(res.data.data.exceptions.user_with_same_phone_already_exists){
            toast.error("Phone number registered");
          }
        })
        .catch((error) => console.log(error));
    } else {
      toast.error("Wrong OTP");
    }
  };

  const handleOtpChange = (val) => {
    setOTP(val);
  };

  return (
    <div className="main login">
      <div className="container text-center">
        <h5 className="heading">OTP</h5>
        <div className="text-center mt-4">
          <label className="mb-2">Enter otp sent to your mobile number</label>
          <OTPInput length={6} onChange={handleOtpChange} />
        </div>

        <button
          type="button"
          className="form-control login mt-5 rounded-5 shadow"
          onClick={() => handleLogin()}
        >
          CONFIRM
        </button>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default OTPVerify;
