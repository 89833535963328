import React, { useEffect, useState } from "react";
import { API_KEY } from "../utils/constants";
import { loginAPI } from "../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../components/loader";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    if (email.length == 0) {
      toast.error("Enter Your Phone Number");
      return;
    } else if (password.length == 0) {
      toast.error("Enter Your Password");
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("__api_key__", API_KEY);
      formData.append("email", `+91${email}`);
      formData.append("password", password);
      await loginAPI(formData)
        .then((res) => {
          if (res.data.state === "OK") {
            localStorage.setItem("user", JSON.stringify(res.data.data.user));
            navigate("/home");
            setIsLoading(false);
          } else if (res.data.data.exceptions.invalid_password) {
            toast.error("Incorrect password");
            setIsLoading(false);
          } else {
            toast.error("No User Found");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    async function fetchData() {
      const userData = await localStorage.getItem("user");
      if (userData != null) {
        navigate("/home");
      }
    }
    fetchData();
  }, []);

  return (
    <div className="main login position-relative">
      <div className="container text-center ">
        <div style={{position: 'absolute', top: 10, right: 10}}>
          <button className="btn rounded-5" onClick={()=>window.open('https://teercounterresults.com/web/thank-you.html', '_blank')} style={{backgroundColor: '#000000', color: 'white'}}><i class="bi bi-download me-2" /><span style={{fontSize: 14}}>Download App</span></button>
        </div>
        <img
          src="./images/logo.jpeg"
          className="img-fluid"
          style={{ width: 150, height: 150, borderRadius: 150 }}
          alt="logo"
        />
        <h5 className="heading mt-3">Welcome Back !</h5>
        <div className="text-start mt-4 bg-white rounded-4 py-1 px-2 position-relative">
          <label>Phone Number</label>
          <input
            name="email"
            placeholder="9999999999"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div
            style={{ position: "absolute", left: 10, bottom: 5 }}
            className="d-flex align-items-center"
          >
            <i class="bi bi-phone fs-4 text-secondary" />
            <span className="mx-1">+91</span>
          </div>
        </div>
        <div className="text-start mt-3 bg-white rounded-4 py-1 px-2 position-relative">
          <label>Password</label>
          <input
            name="password"
            placeholder="............"
            type={showPass ? "password" : "text"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ position: "absolute", left: 10, bottom: 5 }}>
            <i class="bi bi-lock fs-4 text-secondary" />
          </div>
          <div style={{ position: "absolute", right: 10, bottom: 5 }}>
            <i
              class="bi bi-eye-fill fs-4"
              onClick={() => setShowPass(!showPass)}
            ></i>
          </div>
        </div>

        <button
          type="button"
          className="form-control login mt-5 rounded-5 shadow"
          onClick={() => handleLogin()}
        >
          LOG IN
        </button>
        <h6
          className="mt-4 mb-0 forget pointer"
          onClick={() => navigate("/forgot-password")}
        >
          Forget Password? click here
        </h6>
        <button
          type="button"
          className="form-control create mt-4 rounded-5 shadow"
          onClick={() => navigate("/signup")}
        >
          CREATE AN ACCOUNT
        </button>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default Login;
