import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TimeCountDown from "./timeCountDown";
import { toast } from "react-toastify";

const GameCard = ({ item }) => {
  const [isGameClose, setIsGameClose] = useState(true);

  const navigate = useNavigate();
  return (
    <div className="bg-white p-3 game-card my-3" style={{borderRadius: 20}}>
      <div className="flex-row d-flex justify-content-between align-items-start">
        <div className="col-8 text-start">
          <h6 className="title m-0" >{item.name}</h6>
          <label className="text-danger">{item.result}</label>
        </div>
        <div className="col-4 d-flex flex-column align-items-end">
          <div
            className="bg-success px-2 rounded"
            onClick={() => {
              !isGameClose
                ? navigate("/gamedetail", { state: item })
                : toast.error("Game not started yet");
            }}
          >
            <i class="bi bi-play-circle-fill text-white fs-3" />
          </div>
          <TimeCountDown setIsGameClose={setIsGameClose} targetTime={item.close_time} />
        </div>
      </div>
      <div className="flex-row d-flex justify-content-between">
        <label className="time">OPEN - {item.open_time}</label>
        <label className="time">CLOSE - {item.close_time}</label>
      </div>
    </div>
  );
};

export default GameCard;
