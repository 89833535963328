import React, { useEffect, useState } from "react";
import Header from "../components/header";
import { API_KEY } from "../utils/constants";
import { referAndEarnAPI, userDataAPI } from "../api/api";
import { useLocation, useNavigate } from "react-router-dom";
import ShareOnSocial from "react-share-on-social";
import { toast } from "react-toastify";

const favicon = "../images/logo.jpeg";

function ReferAndEarn() {
  const [referralsData, setReferralsData] = useState([]);
  const [totalEarning, setTotalEarning] = useState(0);
  const [referralId, setReferralId] = useState(0);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const userData = await localStorage.getItem("user");
    const user_uid = JSON.parse(userData)?.uid;

    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    formData.append("user_uid", user_uid);
    await userDataAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          getData(res.data.data.user_entity.refer_id);
          setReferralId(res.data.data.user_entity.refer_id);
        }
      })
      .catch((error) => console.log(error));
  };

  const getData = async (refer_id) => {
    const userData = await localStorage.getItem("user");
    const user_uid = JSON.parse(userData)?.uid;

    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    formData.append("user1_uid", user_uid);
    formData.append("refer_id", refer_id);

    await referAndEarnAPI(formData)
      .then((res) => {
        if (res.data.state === "SUCCESS") {
          setReferralsData(res.data.referrals);
          const totalBonus = res.data.referrals.reduce((acc, item) => {
            if (item.status === "1") {
              return acc + parseFloat(item.bonus);
            }
            return acc;
          }, 0);

          setTotalEarning(totalBonus);
        }
      })
      .catch((error) => console.log(error));
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);

    toast.success("Copied To clipboard");
  };

  return (
    <div className="main-2">
      <Header title="Refer and Earn" bgColor isMenu />
      <div
        className="container d-flex flex-column justify-content-between align-items-center"
        style={{ minHeight: "93.5vh", backgroundColor: "#f6f6f6" }}
      >
        <div className="col-12">
          <div
            className="d-flex align-items-end rounded pt-2 pb-3 mt-2"
            style={{ backgroundColor: "#3F51B5" }}
          >
            <div className="col-2 p-1">
              <img src="./images/people-white.png" className="img-fluid" alt="people" />
            </div>
            <div className="col-7 ps-2">
              <h6 className="text-white">YOUR REFER ID:</h6>
              <h4 className="refer-text text-white fw-bold mb-0">{referralId}</h4>
            </div>
            <div className="col-3 ps-2">
              <button className="copy-btn btn" onClick={()=>handleCopy(referralId)}>Copy</button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between p-3 bg-white rounded mt-2">
            <span style={{ fontSize: 14, fontWeight: "bold" }}>
              Your total Earnings:
            </span>
            <span
              style={{ fontSize: 14, fontWeight: "bold", color: "#4CAF50" }}
            >
              {totalEarning}
            </span>
          </div>
          <div className="bg-white rounded mt-2" style={{minHeight: '60vh'}}>
            <h5 className="p-3" style={{ fontSize: 17, fontWeight: "bold" }}>
              YOUR REFERRALS
            </h5>
            <div className="d-flex pb-3">
              <div className="col-1 text-start ps-3 refer-th">Sr. No.</div>
              <div className="col-3 text-center refer-th">Name</div>
              <div className="col-3 text-center refer-th">Recharge</div>
              <div className="col-2 text-center refer-th">Bouns</div>
              <div className="col-3 text-center refer-th">Status</div>
            </div>
            {referralsData.map((item, index) => (
              <div
                className="d-flex rounded shadow mt-1"
                style={{ border: "1px solid #f6f6f6", padding: "15px 0" }}
              >
                <div className="col-1 text-center refer-td">{referralsData.length - index}</div>
                <div className="col-3 text-center refer-td">
                  {item.user1_name}
                </div>
                <div className="col-3 text-center refer-td">{item.paid}</div>
                <div
                  className="col-2 text-center refer-td"
                  style={{ color: "#4CAF50" }}
                >
                  {item.bonus}
                </div>
                <div
                  className="col-3 text-center refer-td"
                  style={{ color: item.status == 1 ? "#4CAF50" : "#ff0000" }}
                >
                  {item.status == 1 ? "SUCCESS" : "PENDING"}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-10 my-4">
          <ShareOnSocial
            textToShare={`🚀Get ₹20 for Joining online Teer Counter! 🚀 \n\nDownload the app: https://teercounterresults.com/web/app.apk\n\nUse my code: ${referralId}\nRecharge ₹200 or more\nOnce you do this, I’ll earn ₹20 You Will get ₹20 and you’ll enjoy great features!\n\nLet me know if you need any help!`}
            link="https://teercounterresults.com/web/app.apk"
            linkTitle="Online Teer Counter"
            linkMetaDesc={`🚀Get ₹20 for Joining online Teer Counter! 🚀 \n
              Download the app: https://teercounterresults.com/web/app.apk\n
              Use my code: ${referralId}\n
              Recharge ₹200 or more\n
              Once you do this, I’ll earn ₹20 You Will get ₹20 and you’ll enjoy great features!\n
              Let me know if you need any help!`}
            linkFavicon={favicon}
            noReferer
          >
            <button className="play-button btn rounded-5">
              REFER AND EARN NOW
            </button>
          </ShareOnSocial>
        </div>
      </div>
    </div>
  );
}

export default ReferAndEarn;
