import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import { API_KEY } from '../utils/constants';
import { playGameAPI } from '../api/api';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../components/loader';
import { toast } from 'react-toastify';
import { amountValidation, oneDigitValidation } from '../utils/validation';

function BsHousePlay() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, type } = location.state || {};
  const [gameNumber, setGameNumber] = useState([]);
  const [start, setStart] = useState('');
  const [amount, setAmount] = useState('');
  const [total, setTotal] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [walletAmount, setWalletAmount] = useState("");

  useEffect(() => {
    let input = [];
    for (let i = 0; i < 100; i++) {
      let title = i.toString().padStart(2, '0');
      input.push({ id: i, title: title, value: '' });
    }
    setGameNumber(input);
  }, []);

  const handleSubmit = () => {
    if (start.length == 0) {
      toast.error("Enter start digit");
    }else if(amount.length == 0){
      toast.error('Enter points')
    } else if (Number(amount) < 10) {
      toast.error("Bid amount should be minimum 10");
    }  else {
    setIsSubmit(true);
    const filteredInput = gameNumber.filter((item) =>
      item.title.toString().startsWith(start.toString())
    );
    const updatedGame = gameNumber.map((item) => {
      const matchingItem = filteredInput.find(
        (filteredItem) => filteredItem.title === item.title
      );
      if (matchingItem) {
        return { ...item, value: amount };
      }
      return item;
    });

    const totalValue = updatedGame.reduce(
      (total, game) => Number(total) + Number(game.value),
      0
    );
    setTotal(totalValue);
    setGameNumber(updatedGame);
  }
  };

  const handlebid = async () => {
     if(Number(total) > Number(walletAmount)){
      toast.error("Insufficient funds");
    } else{
   await SubmitFR()
   await SubmitSR()
  }
  };

  const SubmitFR = async () =>{
    setIsLoading(true)
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData).uid;
    const gameName = name == 'KHANAPARA BS' ? 'KHANAPARA TEER - FR':'SHILLONG TEER - FR';
    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    formData.append('game_name', gameName);
    formData.append('game_type', type);
    gameNumber.map((item, index)=>{
        const value = Number(item.value)/2 || ''
        formData.append(`field_${index}`, value);
  })
    await playGameAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          console.log(res.data);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  }

  const SubmitSR = async () =>{
    setIsLoading(false)
    const userData = await localStorage.getItem('user');
    const user_uid = JSON.parse(userData).uid;
    const gameName = name == 'KHANAPARA BS' ? 'KHANAPARA TEER - SR':'SHILLONG TEER - SR';
    const formData = new FormData();
    formData.append('__api_key__', API_KEY);
    formData.append('user_uid', user_uid);
    formData.append('game_name', gameName);
    formData.append('game_type', type);
    gameNumber.map((item, index)=>{
        const value = Number(item.value)/2 || ''
        formData.append(`field_${index}`, value);
  })
    await playGameAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          console.log(res.data);
          setIsLoading(false)
          navigate('/home')
            toast.success("Played Successfully");
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <div className='main-2'>
      <Header title='House Play' bgColor isBack setWalletAmount={setWalletAmount} />
      <div className='container'>
        <label className='mt-2'>Start</label>
        <input
          className='form-control shadow-sm'
          disabled={isSubmit}
          placeholder='Enter'
          name='number'
          value={start}
          onChange={(e) => {oneDigitValidation.test(e.target.value) && setStart(e.target.value)}}
        />
        <label className='mt-2'>Bidding Amount</label>
        <input
          className='form-control shadow-sm'
          disabled={isSubmit}
          placeholder='Enter'
          name='number'
          value={amount}
          onChange={(e) => {amountValidation.test(e.target.value) && setAmount(e.target.value)}}
        />
        {!isSubmit && (
          <button
            className='play-button my-3 btn'
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
        )}
        {isSubmit && (
          <div>
            <div className='col-12 mb-2 mt-4 text-center shadow-sm py-2'>
              <label className='col-6'>House</label>
              <label className='col-6'>Amount</label>
            </div>
            {gameNumber.map(
              (item) =>
                item.value != '' && (
                  <div className='col-12 my-2 text-center'>
                    <label className='col-6'>{item.title}</label>
                    <label className='col-6'>{item.value}</label>
                  </div>
                )
            )}

            <div
              className='d-flex justify-content-between w-100 align-items-center p-2 rounded-2'
              style={{ backgroundColor: 'rgb(108 98 255)' }}
            >
              <label className='text-white fw-bold'>Total:</label>
              <div className='col-2 p-2 bg-white text-center rounded-2'>{total}</div>
            </div>
            <button className='play-button my-3 btn' onClick={()=>handlebid()}>BID</button>
          </div>
        )}
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}

export default BsHousePlay;
