import React, { useEffect, useState } from "react";
import Header from "../components/header";
import GameCard from "../components/gameCard";
import { API_KEY } from "../utils/constants";
import { contactUsAPI, gameListAPI, sliderImageAPI } from "../api/api";
import ImageSlider from "../components/imageSlider";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import AnimatedLoader from "../components/animatedLoader";

function Home() {
  const navigate = useNavigate();
  const [gameList, setGameList] = useState([]);
  const [fcGamesList, setFcGameList] = useState([]);
  const [image, setImage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [whatsapp, setWhatsapp] = useState('');
  const [telegram, setTelegram] = useState('');
  const [phone, setPhone] = useState('');

  const getGameList = async () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    await gameListAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          setGameList(res.data.data.standardGames);
          setFcGameList(res.data.data.fcGames);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  const getSliderImage = async () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append("__api_key__", API_KEY);
    await sliderImageAPI(formData)
      .then((res) => {
        if (res.data.state === "OK") {
          setImage(res.data.data.images);
          setIsLoading(false)
        }
      })
      .catch((error) => console.log(error));
  };

  const getFundData = async () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append('__api_key__', API_KEY);

    await contactUsAPI(formData)
      .then((res) => {
        if (res.data.state === 'OK') {
          setIsLoading(false)
          setPhone(res.data.data.meta.contact_number);
          setWhatsapp(res.data.data.meta.whatsapp_number);
          setTelegram(res.data.data.meta.telegram_number);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getGameList();
    getSliderImage();
    getFundData();
  }, []);

  return (
    <div className="main-2 bg-banner">
      <Header isMenu title={"name"} isHome />
      <div>
        <div className="container text-center ">
        <div className="d-flex justify-content-end">
          <button className="btn rounded-5" onClick={()=>window.open('https://teercounterresults.com/web/thank-you.html', '_blank')} style={{backgroundColor: '#000000', color: 'white'}}><i class="bi bi-download me-2" /><span style={{fontSize: 14}}>Download App</span></button>
        </div>
          <div className="banner mx-auto mt-2">
            <ImageSlider images={image} />
          </div>
          <div className="d-flex flex-wrap justify-content-between col-10 mx-auto" style={{height:110}}>
            {fcGamesList.map((item) => (
              <div
                className="game-button rounded-5 pointer"
                style={{ backgroundColor: "#6c62ff" }}
                onClick={() => navigate("/fc-game", { state: item })}
              >
                {item.name}
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-between align-items-center pe-4 ps-2 mt-3">
            <div className="d-flex align-items-center">
              <div
                style={{ backgroundColor: "green" }}
                className="wa-media-icon align-items-center justify-content-center d-flex"
              >
                <img
                  src="./images/whatsapp.png"
                  className="img-fluid p-1"
                  alt="whatsapp"
                  onClick={()=>window.open(`https://wa.me/${whatsapp}`, '_blank')}
                />
              </div>
              <img src="./images/call.png" className="media-icon" onClick={()=> window.open(`tel:${phone}`, '_blank')} alt="call" />
              <img
                src="./images/telegram.png"
                className="media-icon"
                alt="telegram"
                onClick={()=>window.open(`https://t.me/${telegram}`, '_blank')}
              />
            </div>
            <button
              className="btn rounded-5 text-white border border-1 px-3"
              onClick={() => navigate("/add")}
              style={{ backgroundColor: "#6c62ff" }}
            >
              Add +
            </button>
          </div>
        </div>
        <div
          className="game-list-container pb-3 pt-2 px-2 rounded-5 mt-3"
          style={{ backgroundColor: "#6c62ff" }}
        >
          {gameList.map((item) => (
            <GameCard item={item} />
          ))}
        </div>
      </div>
      <AnimatedLoader isLoading={isLoading} />
    </div>
  );
}

export default Home;
